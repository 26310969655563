import { RoleUtils } from '../types/user';
import { useUser } from './UserContext';

export enum PortalLink {
  HostHome = '/host/minigames',
  AdminHome = '/admin/gamepacks',
  HostGamePack = '/host/gamepacks',
  AdminGamePack = '/admin/gamepacks',
  OrganizerHome = '/home',
  LearningHome = '/learning',
  LearningAdmin = '/learning/admin',
}

export function usePortalRoutePrefix(): string {
  const isAdmin = RoleUtils.isAdmin(useUser());
  return isAdmin ? 'admin' : 'host';
}
